/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
// import PropTypes from "prop-types";

import React, { useState, useEffect, useContext } from "react";
import tw, { css } from "twin.macro";
import { AppContext } from "~context/AppContext.jsx";
import { CacheContext } from "~context/CacheContext.jsx";
import { DocumentContext } from "~context/DocumentContext.jsx";
import * as A from "~components/styles/Animations.jsx";
import Grid from "~components/styles/Grid.jsx";
import * as Icon from "~components/svg/Icons.jsx";
import * as T from "~components/styles/Typography.jsx";
import Diamond from "~components/Diamond.jsx";
import Explainer from "~components/Explainer.jsx";
import { getRandomIntByRange } from "~utils/helpers";

import whispers from "~assets/images/whispers-desktop.png";
import whispersXS from "~assets/images/whispers-mobile.png";

const DiamondGrid = ({ filters, loaded, rendering, reset, sorting }) => {
  // =============================================================================
  // react

  const { setResultCount } = useContext(AppContext);
  const { diamonds } = useContext(CacheContext);
  const { screen } = useContext(DocumentContext);

  const [loadingIcon, setLoadingIcon] = useState(null);
  const [visibleDiamonds, setVisibleDiamonds] = useState([]);

  // =============================================================================
  // component data

  const MAX_RESULT_SET = screen === `xs` ? 20 : 99;

  const diamondIconStyle = [tw`w-8 h-8`];

  const diamondIcons = {
    Round: <Icon.Round styles={diamondIconStyle} selected />,
    Oval: <Icon.Oval styles={diamondIconStyle} selected />,
    Cushion: <Icon.Cushion styles={diamondIconStyle} selected />,
    Emerald: <Icon.Emerald styles={diamondIconStyle} selected />,
    Asscher: <Icon.Asscher styles={diamondIconStyle} selected />,
    Pear: <Icon.Pear styles={diamondIconStyle} selected />,
    "Rose Cut": <Icon.RoseCut styles={diamondIconStyle} selected />,
    Marquise: <Icon.Marquise styles={diamondIconStyle} selected />,
    Trillion: <Icon.Trillion styles={diamondIconStyle} selected />,
    "Old European": <Icon.OldEuropean styles={diamondIconStyle} selected />,
    "Old Mine": <Icon.OldMine styles={diamondIconStyle} selected />,
    Radiant: <Icon.Radiant styles={diamondIconStyle} selected />,
    Princess: <Icon.Princess styles={diamondIconStyle} selected />
  };

  // =============================================================================
  // filter methods

  const priceFilter = (diamond) => {
    let priceVar = false;

    if (!filters?.price) {
      priceVar = true;
    }

    if (filters?.price?.max) {
      const parsedDiamondPrice = parseFloat(diamond.Cost.replace(/,/g, ``));

      priceVar =
        parsedDiamondPrice >= filters.price.min &&
        parsedDiamondPrice <= filters.price.max;
    }

    return priceVar;
  };

  const caratFilter = (diamond) => {
    let caratVar = false;

    if (!filters?.carat) {
      caratVar = true;
    }

    if (filters?.carat?.max) {
      const parsedDiamondCarat = parseFloat(diamond.Carat);

      caratVar =
        parsedDiamondCarat >= filters.carat.min / 100 &&
        parsedDiamondCarat <= filters.carat.max / 100;
    }

    return caratVar;
  };

  const shapeFilter = (diamond) => {
    let shapeVar = false;

    if (filters?.shape) {
      shapeVar = filters.shape.includes(diamond.Shape);
    }

    if (!filters?.shape || filters.shape.length === 0) {
      shapeVar = true;
    }

    return shapeVar;
  };

  const colorFilter = (diamond) => {
    let colorVar = false;

    if (filters?.color) {
      if (diamond?.Color?.includes(`Fancy`)) {
        colorVar =
          filters.color.includes(diamond.Color) ||
          filters.color.includes(`All Fancy Colors`);
      } else {
        colorVar = filters.color.includes(diamond.Color);
      }
    }

    if (
      !filters?.color ||
      filters.color.length === 0 ||
      filters.color.includes(`Any`)
    ) {
      colorVar = true;
    }

    return colorVar;
  };

  const cutFilter = (diamond) => {
    let cutVar = false;

    if (filters?.cut) {
      cutVar = filters.cut.includes(diamond.Cut_Grade);
    }

    if (
      !filters?.cut ||
      filters.cut.length === 0 ||
      filters.cut.includes(`All`)
    ) {
      cutVar = true;
    }

    return cutVar;
  };

  const typeFilter = (diamond) => {
    let typeVar = false;

    if (filters?.type) {
      typeVar =
        (filters.type.includes(`Natural Diamond`) &&
          diamond.Type === `Natural`) ||
        (filters.type.includes(`Lab Diamond`) && diamond.Type === `Lab`) ||
        (filters.type.includes(`Moissanite`) && diamond.Type === `Moissanite`);
    }

    if (!filters?.type || filters.type.length === 0) {
      typeVar = true;
    }

    return typeVar;
  };

  const clarityFilter = (diamond) => {
    let clarityVar = false;

    if (filters?.clarity) {
      if (
        diamond?.Clarity?.toLowerCase()?.includes(`salt`) &&
        diamond?.Clarity?.toLowerCase()?.includes(`pepper`)
      ) {
        clarityVar = filters.clarity.includes(`Salt and Pepper`);
      } else {
        clarityVar = filters.clarity.includes(diamond?.Clarity);
      }
    }

    if (!filters?.clarity || filters.clarity.length === 0) {
      clarityVar = true;
    }

    return clarityVar;
  };

  const gradingLabFilter = (diamond) => {
    let gradingVar = false;

    if (filters?.[`grading lab`]) {
      gradingVar = filters[`grading lab`].includes(diamond.Lab);
    }

    if (
      !filters?.[`grading lab`] ||
      filters?.[`grading lab`].length === 0 ||
      filters?.[`grading lab`].includes(`All`)
    ) {
      gradingVar = true;
    }

    return gradingVar;
  };

  const seeInPersonFilter = (diamond) => {
    let inPersonVar = false;

    if (filters?.[`see in person`]) {
      inPersonVar =
        filters[`see in person`].toLowerCase() === `see all` ||
        (filters[`see in person`].toLowerCase() === `see irl` &&
          diamond?.In_Person?.toLowerCase() === `yes`);
    }

    if (
      !filters?.[`see in person`] ||
      filters?.[`see in person`].length === 0
    ) {
      inPersonVar = true;
    }

    return inPersonVar;
  };

  const supplierStandardsFilter = (diamond) => {
    let supplierVar = false;

    if (filters?.[`supplier standards`]) {
      supplierVar = filters[`supplier standards`].includes(
        `Level ${diamond?.Supplier_Standard}`
      );
    }

    if (
      !filters?.[`supplier standards`] ||
      filters?.[`supplier standards`].length === 0
    ) {
      supplierVar = true;
    }

    return supplierVar;
  };

  const polishFilter = (diamond) => {
    let polishVar = false;

    if (filters?.polish) {
      polishVar = filters.polish.includes(diamond.Polish);
    }

    if (
      !filters?.polish ||
      filters.polish.length === 0 ||
      filters.polish.includes(`Any`)
    ) {
      polishVar = true;
    }

    return polishVar;
  };

  const symmetryFilter = (diamond) => {
    let symmetryVar = false;

    if (filters?.symmetry) {
      symmetryVar = filters.symmetry.includes(diamond.Symmetry);
    }

    if (
      !filters?.symmetry ||
      filters.symmetry.length === 0 ||
      filters.symmetry.includes(`Any`)
    ) {
      symmetryVar = true;
    }

    return symmetryVar;
  };

  const fluorescenceFilter = (diamond) => {
    let fluorescenceVar = false;

    if (filters?.fluorescence) {
      fluorescenceVar = filters.fluorescence.includes(diamond.Fluorescence);
    }

    if (
      !filters?.fluorescence ||
      filters.fluorescence.length === 0 ||
      filters.fluorescence.includes(`Any`)
    ) {
      fluorescenceVar = true;
    }
    return fluorescenceVar;
  };

  const crownAngleFilter = (diamond) => {
    let crownAngleVar = false;

    if (filters?.[`crown angle`]?.max) {
      crownAngleVar =
        diamond.Crown_Angle >= filters[`crown angle`].min &&
        diamond.Crown_Angle <= filters[`crown angle`].max;
    }

    if (!filters?.[`crown angle`]) {
      crownAngleVar = true;
    }

    return crownAngleVar;
  };

  const ratioFilter = (diamond) => {
    let ratioVar = false;

    if (filters?.[`l/w ratio`]?.max) {
      const parsedDiamondRatio = parseFloat(diamond.Ratio * 100);
      ratioVar =
        parsedDiamondRatio >= filters[`l/w ratio`].min &&
        parsedDiamondRatio <= filters[`l/w ratio`].max;
    }

    if (!filters?.[`l/w ratio`]) {
      ratioVar = true;
    }

    return ratioVar;
  };

  const tableFilter = (diamond) => {
    let tableVar = false;

    if (filters?.table?.max) {
      tableVar =
        diamond.Table >= filters.table.min &&
        diamond.Table <= filters.table.max;
    }

    if (!filters?.table) {
      tableVar = true;
    }

    return tableVar;
  };

  const depthFilter = (diamond) => {
    let depthVar = false;

    if (filters?.depth?.max) {
      depthVar =
        diamond.Depth_ >= filters.depth.min &&
        diamond.Depth_ <= filters.depth.max;
    }

    if (!filters?.depth) {
      depthVar = true;
    }

    return depthVar;
  };

  const fancyColorIntensity = (diamond) => {
    let fancyVar = false;

    if (filters?.[`fancy color intensity`]) {
      fancyVar = filters[`fancy color intensity`].includes(
        diamond.Color_Intensity
      );
    }

    if (
      !filters?.[`fancy color intensity`] ||
      filters?.[`fancy color intensity`].length === 0
    ) {
      fancyVar = true;
    }

    return fancyVar;
  };

  // =============================================================================
  // methods

  /**
   * -----------------------------------------------------------------------------
   * Return an array of diamonds sorted on the inherited [sorting] type.
   * @param  {array} unsortedDiamonds   The default state diamond array
   * @return {array} sortedDiamonds     The sorted diamonds array
   */
  const applySorting = (unsortedDiamonds) => {
    const sortedDiamonds = JSON.parse(JSON.stringify(unsortedDiamonds));

    switch (sorting) {
      // case `quality-score`:
      //   sortedDiamonds.sort((a, b) => +b?.node?.Quality_Score - +a?.node?.Quality_Score);

      //   break;

      case `size-score`:
        sortedDiamonds.sort(
          (a, b) => +b?.node?.Size_Score - +a?.node?.Size_Score
        );

        break;

      case `total-score`:
        sortedDiamonds.sort(
          (a, b) => +b?.node?.Total_Score - +a?.node?.Total_Score
        );

        break;

      case `price-high-low`:
        sortedDiamonds.sort((a, b) => {
          const costA = parseFloat(a?.node?.Cost?.replace(/,/g, ``));
          const costB = parseFloat(b?.node?.Cost?.replace(/,/g, ``));

          if (costA > costB) {
            return -1;
          }

          if (costB < costA) {
            return 1;
          }

          return 0;
        });

        break;

      case `price-low-high`:
      default:
        sortedDiamonds.sort((a, b) => {
          const costA = parseFloat(a?.node?.Cost?.replace(/,/g, ``));
          const costB = parseFloat(b?.node?.Cost?.replace(/,/g, ``));

          if (costA < costB) {
            return -1;
          }

          if (costB > costA) {
            return 1;
          }

          return 0;
        });

        break;
    }

    const explainers = [
      {
        heading: `THE CUT`,
        body: `The diamond cut is the most important element to consider when buying a diamond. It is the biggest factor in creating sparkle and fire.`,
        insertAt: 6,
        link: `https://frankdarling.com/measurements-vs-carat-weight/`
      },
      {
        heading: `THE COLOR`,
        body: `Colored diamonds contain interstitial impurities or structural defects that cause the coloration; pure diamonds are perfectly transparent and colorless.`,
        insertAt: 8,
        link: `https://frankdarling.com/measurements-vs-carat-weight/`
      },
      {
        heading: `THE CARAT`,
        body: `Diamond carat weight is the measurement of how much a diamond weighs.`,
        insertAt: 13,
        link: `https://frankdarling.com/measurements-vs-carat-weight/`
      },
      {
        heading: `THE CLARITY`,
        body: `VVS2 clarity diamonds have slightly more inclusions than the VVS1 grade. A VVS diamond is an excellent quality diamond and clarity grade.`,
        insertAt: 18,
        link: `https://frankdarling.com/measurements-vs-carat-weight/`
      }
    ];

    explainers.forEach((explainer) => {
      if (sortedDiamonds.length > explainer.insertAt) {
        sortedDiamonds.splice(explainer.insertAt, 1, explainer);
      } else {
        sortedDiamonds.push(explainer);
      }
    });

    return sortedDiamonds;
  };

  /**
   * -----------------------------------------------------------------------------
   * Save the first available {MAX_RESULT_SET} diamonds as visible in the grid.
   * @return {null}
   */
  const setDefaultDiamonds = () => {
    const defaultDiamonds = [];

    applySorting(diamonds).forEach((diamond) => {
      if (diamond?.insertAt || defaultDiamonds.length >= MAX_RESULT_SET) {
        return;
      }

      defaultDiamonds.push(diamond.node);
    });

    setVisibleDiamonds(defaultDiamonds);
  };

  /**
   * -----------------------------------------------------------------------------
   * Determine whether or not the state [filters] are valid.
   * @return {boolean} result
   */
  const filtersUnavailable = () => {
    let result = false;

    if (Object.keys(filters).length === 0) {
      result = true;
    } else {
      result = Object.values(filters).every(
        (key) =>
          (Array.isArray(key) && key.length === 0) ||
          (!Array.isArray(key) && Object.values(key).length === 0)
      );
    }

    return result;
  };

  // =============================================================================
  // hooks

  /**
   * -----------------------------------------------------------------------------
   * useEffect [rendering]
   * Fires whenever the filters in the calling component are changed, giving the
   * template an opportunity to show fades/animations/etc.
   * @return {null}
   */
  // useEffect(() => {
  //   //
  // }, [rendering]);

  /**
   * -----------------------------------------------------------------------------
   * useEffect [diamonds]
   * Tightly control the initial assignment of diamond data.
   * @return {null}
   */
  useEffect(() => {
    if (diamonds?.[0] && !visibleDiamonds?.[0]) {
      setDefaultDiamonds();
    }
  }, [diamonds]);

  /**
   * -----------------------------------------------------------------------------
   * useEffect [filters]
   * Filter and assign the cached diamonds on inherited filter value change.
   * @return {null}
   */
  useEffect(() => {
    if (!loaded) {
      return;
    }

    let compatibleIcons = [];

    if (filters?.shape?.[0]) {
      compatibleIcons = [diamondIcons[filters.shape[filters.shape.length - 1]]];
    } else {
      Object.keys(diamondIcons).forEach((diamondIconKey) => {
        compatibleIcons.push(diamondIcons[diamondIconKey]);
      });
    }

    setLoadingIcon(
      compatibleIcons[getRandomIntByRange(0, compatibleIcons.length - 1)]
    );

    //

    if (filtersUnavailable()) {
      setDefaultDiamonds();
    } else {
      const matchingDiamonds = [];

      applySorting(diamonds).forEach((diamond) => {
        if (diamond?.insertAt || matchingDiamonds.length >= MAX_RESULT_SET) {
          return;
        }

        if (
          priceFilter(diamond.node) &&
          caratFilter(diamond.node) &&
          shapeFilter(diamond.node) &&
          colorFilter(diamond.node) &&
          cutFilter(diamond.node) &&
          typeFilter(diamond.node) &&
          clarityFilter(diamond.node) &&
          gradingLabFilter(diamond.node) &&
          seeInPersonFilter(diamond.node) &&
          supplierStandardsFilter(diamond.node) &&
          polishFilter(diamond.node) &&
          symmetryFilter(diamond.node) &&
          fluorescenceFilter(diamond.node) &&
          crownAngleFilter(diamond.node) &&
          ratioFilter(diamond.node) &&
          tableFilter(diamond.node) &&
          depthFilter(diamond.node) &&
          fancyColorIntensity(diamond.node)
        ) {
          matchingDiamonds.push(diamond.node);
        }
      });

      setVisibleDiamonds(matchingDiamonds);
    }
  }, [filters, sorting]);

  /**
   * -----------------------------------------------------------------------------
   * useEffect [visibleDiamonds]
   * Update result count through AppContext.
   */
  useEffect(() => {
    setResultCount(visibleDiamonds?.[0] ? visibleDiamonds.length : null);
  }, [visibleDiamonds]);

  // =============================================================================
  // render

  return (
    <>
      {(rendering && (
        <section
          css={[
            css`
              ${A.Keyframes(`appear`, `0.5s ${A.EASING_CUBIC} forwards`)}

              @media screen and (max-width: 768px) {
                min-height: 100vh;
              }
            `,
            tw`w-full relative pt-24 md:pt-32 text-koamaru`
          ]}
        >
          <div tw="relative flex items-center justify-center pt-8">
            <T.Heading font="4" level="3" serif>
              Loading...
            </T.Heading>

            <div
              css={[
                css`
                  ${A.Keyframes(
                    `appearSpin`,
                    `0.75s ${A.EASING_CUBIC} forwards`
                  )}
                `,
                tw`w-8 h-8 relative flex items-center justify-center ml-3`
              ]}
            >
              {loadingIcon}
            </div>
          </div>
        </section>
      )) || (
        <section
          css={[
            css`
              @media screen and (max-width: 768px) {
                min-height: 100vh;
              }
            `
          ]}
        >
          {(visibleDiamonds?.[0] && (
            <ul css={[tw`w-full relative flex flex-wrap`]}>
              {visibleDiamonds.map((diamond, diamondIndex) => (
                <li
                  key={
                    diamond?.insertAt
                      ? `explainer-${diamond.insertAt}`
                      : `${diamond.Diamond_ID}-${diamondIndex}`
                  }
                  css={[
                    css`
                      ${A.Keyframes(
                        `appearRight`,
                        `0.75s ${A.EASING_CUBIC} forwards`,
                        `${diamondIndex * 25 < 500 ? diamondIndex * 25 : 500}ms`
                      )}

                      -webkit-mask-image: -webkit-radial-gradient(white, black);
                    `,
                    tw`w-full xs:w-1/2 sm:w-1/3 md:w-1/4 relative overflow-hidden`
                  ]}
                >
                  {(diamond?.insertAt && <Explainer explainer={diamond} />) || (
                    <Diamond diamond={diamond} index={diamondIndex} />
                  )}
                </li>
              ))}
            </ul>
          )) || (
            <article
              css={[
                css`
                  @media screen and (max-width: 1024px) {
                    height: 100vh;
                  }
                  height: calc(100vh - 10rem);
                `,
                tw`w-full relative pt-32 flex items-center justify-center text-center text-koamaru`
              ]}
            >
              <Grid styles={[tw`h-full`]}>
                <div
                  css={[
                    tw`col-span-22 md:col-span-8 col-start-2 md:col-start-9 h-full relative flex flex-col justify-between`
                  ]}
                >
                  <div
                    css={[
                      css`
                        ${A.Keyframes(
                          `appearDown`,
                          `1s ${A.EASING_CUBIC} forwards`
                        )}
                      `
                    ]}
                  >
                    <T.Body serif>
                      No diamonds found for those specific filters, darling.
                    </T.Body>

                    <T.Body serif>
                      <span>
                        Never fear, there about 30,000 others in this database.
                        Try tweaking your filter in the blue bar above, and
                        watch the results in real time. And if you want to see
                        everything and refine from there,{` `}
                      </span>
                      <button
                        type="button"
                        tw="inline-block underline"
                        onClick={reset}
                      >
                        click here.
                      </button>
                    </T.Body>
                  </div>

                  <figure
                    css={[
                      css`
                        ${A.Keyframes(
                          `appear`,
                          `1s ${A.EASING_CUBIC} forwards`
                        )}

                        padding-right: calc(100vw / 24);
                        padding-left: calc(100vw / 24);
                      `
                    ]}
                  >
                    <img src={whispers} alt="Whispers" />
                  </figure>
                </div>
              </Grid>
            </article>
          )}
        </section>
      )}
    </>
  );
};

export default DiamondGrid;
